.form-control {
    &:focus {
        color: $black;
        box-shadow: none;
        border: 1px solid $blue;
    }
}

.form-control {
    &.is-invalid {
        background-image: unset !important;
    }
}