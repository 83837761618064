.form-check.radio {
    padding: 14px;
    position: relative;
    .form-check-label {
        &::before {
            display: block;
            content: "";
            background: #FFFFFF;
            border: 1px solid #B4B9C5;
            border-radius: 4px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    input:checked+.form-check-label {
        color: $black;
        &::before {
            background: #ECF9FF;
            border: 1px solid #199CDB;
        }
    }
}

.check-box {
    background: #FFFFFF;
    border: 1px solid #B4B9C5;
    border-radius: 4px;
    padding: 14px;
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23199CDB'/%3e%3c/svg%3e");
    &:focus {
        box-shadow: none;
    }
}

.form-check-input {
    border: 1px solid #B5B5B5;
    margin-left: 0!important;
    &:checked {
        border-color: $blue;
        background-color: white;
        width: 18px;
        height: 18px;
    }
}

.form-check-label {
    font-weight: 600;
    font-size: 18px;
    color: $gray-800
}