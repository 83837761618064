.accordion {
    &.on-styled {
        margin-bottom: 35px;
        @media (max-width: 992px) {
            margin-bottom: 30px;
        }
        .accordion-item {
            background-color: transparent;
            border: none;
            margin-bottom: 0;
            .accordion-button {
                background-color: transparent;
                color: white;
                font-weight: 600;
                font-size: 1.5rem;
                padding: 12px 0;
                @media (max-width: 576px) {
                    padding: 4px 0;
                    align-items: start;
                }
                &::before {
                    display: block;
                    @include icon-styles;
                    content: icon-char(plus);
                    padding-right: 20px;
                    @media (max-width: 576px) {
                        padding-right: 12px;
                    }
                }
                &::after {
                    background-image: none;
                }
                &:not(.collapsed) {
                    background-color: transparent;
                    box-shadow: none;
                }
                &[aria-expanded=true] {
                    &::before {
                        content: icon-char(minus);
                    }
                }
                @media (max-width: 992px) {
                    font-size: 1.25rem;
                }
            }
            .accordion-body {
                color: white;
                padding: 12px 45px;
            }
        }
        &.dark {
            .accordion-item {
                .accordion-button {
                    font-weight: 500;
                    color: $gray-900;
                    font-size: 1.375rem;
                    line-height: 28px;
                    padding: 6px 0;
                    align-items: start;
                    &::before {
                        display: block;
                        @include icon-styles;
                        content: icon-char(plus);
                        padding-right: 17px;
                        @media (max-width: 576px) {
                            padding-right: 12px;
                        }
                    }
                    &::after {
                        background-image: none;
                    }
                    &:not(.collapsed) {
                        background-color: transparent;
                        box-shadow: none;
                    }
                    &[aria-expanded=true] {
                        &::before {
                            content: icon-char(minus);
                        }
                    }
                    @media (max-width: 992px) {
                        font-size: 1.25rem;
                        line-height: 1.375rem;
                    }
                    &[aria-expanded="true"] {
                        color: $blue;
                    }
                }
                .accordion-body {
                    color: #5F6677;
                    font-size: 1.125rem;
                    padding: 6px 0 6px 42px;
                    @media (max-width: 992px) {
                        font-size: 1rem;
                        padding: 6px 0 6px 32px;
                    }
                    a {
                        color: #5F6677;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.accordion-item {
    margin-bottom: 9px;
    border-radius: 10px;
    .accordion-button {
        font-weight: 600;
        font-size: 18px;
        border-radius: 10px;
        &.collapsed, &[aria-expanded="false"] {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &[aria-expanded="true"] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .icon-style {
            width: 54px;
            height: 54px;
            @media (max-width: 992px) {
                width: 28px;
                height: 28px;
            }
        }
        &:focus {
            box-shadow: none;
        }
    }
    .accordion-body {
        font-size: 0.813rem;
    }
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &.collapsed {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}