.floating-search {
    display: flex;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        height: calc(100% - 5px);
        width: calc(3.125rem + 1px);
        margin-left: -3.125rem; 
        background: linear-gradient(270deg, $blue 31.52%, rgba(25, 156, 219, 0) 100%);
    }

    @media (max-width: 575px) {
        &.no-before {
            &::before {
                display: none !important;
            }
        }
    }

    .search-input-wrapper {
        width: 70px;
        opacity: 0;
        display: none;

        .input-group-text {
            background: $white;
            color: $gray-600;
            border-right: none;
            padding: 0px 13px;
        }

        .form-control {
            border-left: none;
            padding: 0px 13px;
            padding-left: 0;
            box-shadow: none!important;
        }
    }

    .search-icon-toggle {
        margin-left: 0.2rem;
        padding-right: 0px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    &.active {
        .search-input-wrapper {
            width: 250px;
            display: flex;
            opacity: 1;
            transition: opacity .5s;
        }
        
        .search-icon-toggle {
            padding-top: 6px;
            padding-bottom: 5px;
            padding-right: 4px;
            
            icon {
                font-size: 1rem!important;
                &::before {
                    content: $icon-name-close;
                    transition: content .5s;
                }
            }
        }

        @media (max-width: 575px) {
            position: absolute;
            width: 100%;
            background-color: $blue;

            .search-input-wrapper {
                width: 100%;
            }
        }
    }
}

@media (max-width: 575px) {
    .floating-search-opened {
        .horizontal-scroll {
            opacity: 0;
        }
    }
}
