.checkbox .form-check-input {
    background: white;
    border: 2px solid #EDEFF3;
}

.checkbox {
    padding-left: 30px;
    position: relative;
    display: block;
    margin-bottom: 10px;
}

.checkbox input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    margin-left: -30px;
}

.checkbox input:checked+label::before {
    border-color: $blue;
}

.checkbox input:checked+label::after {
    font-family: "IconsFont";
    content: $icon-name-checkbox;
    display: inline-block;
    font-size: 9px;
    color: white;
    position: absolute;
    left: 5px;
    top: 4px;
    margin-left: -30px;
}

.checkbox label {
    font-size: 14px;
    font-weight: normal;
    color: $black;
    display: inline-block;
    position: relative;
    padding-left: 1rem;
}

.checkbox label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    margin-left: -30px;
    border: 1px solid #B4B9C5;
    border-radius: 0.188rem;
    background-color: #fff;
}

.checkbox.disabled label {
    color: $black;
}

.checkbox.disabled label::before {
    opacity: .54;
}

.checkbox input:checked+label::before {
    border: none;
    background-color: $blue;
}

.checkbox label::before {
    border-radius: 0.188rem;
}