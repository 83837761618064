
.calculator {
  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    cursor: pointer;
    padding: 4px;
  }

  &__blog {
    display: none!important;
    position:fixed;
    right: 0;
    bottom: 10px;
    z-index: 20;
    max-width: 100%;
    width: 100%;

    @media (min-width: 500px) {
      right: 25px;
      max-width: 420px;
    }

    &.active {
      display: block!important;
    }

    .form-control {
      font-size: .875rem!important;
    }

    .input-group-text {
      font-weight: 400;
      font-size: .875rem;
      color: #1d222e;
      padding-right: 0;
      border-right: none;
    }

    .input-group {
      .form-control {
        padding-left: 0.25rem;
        border-left: none;
      }
    }
    .price-text {
      sup {
        top: -20px;
        right: 15px;
      }
    }
  }

  &__input {
    flex: 1;
  }

  &__blog-toggle-btn {
    z-index: 10;
    max-width: 200px;
    right: 25px;
    position: fixed;
    bottom: 0;
  }
}