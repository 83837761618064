.card {
    background-color: white;
    border: 1px solid #D5DBE8;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
    border-radius: 0.625rem;
    .card-header {
        background-color: transparent;
        border-bottom: 1px solid #E0E8F5;
        padding: 1.625rem 0;
        font-weight: 600;
        font-size: 1.625rem;
        color: $black;
    }
    .card-body {
        padding: 1.875rem;
        .card-title {
            font-weight: 600;
            font-size: 1.375rem;
            line-height: 1.5rem;
            color: $black;
        }
        .card-text {
            font-weight: 500;
            font-size: 0.813rem;
            color: $black;
        }
    }
    .card-footer {
        background-color: transparent;
        border-top: none;
        padding: 14px 40px 28px 40px;
    }
}