.btn {
    line-height: 1.3;
    &:focus {
        box-shadow: none;
    }
    // @media (max-width: 992px) {
    //     font-size: 0.813rem;
    // }
}

.btn-outline-dark {
    font-size: 0.938rem;
    padding: .563rem 1.75rem;
    &:hover {
        color: white;
        background-color: $green;
        border-color: $green;
    }
    &:focus {
        box-shadow: none;
    }
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
    box-shadow: none;
}

.btn-outline-secondary {
    font-size: 0.938rem;
    background-color: white;
    padding: .563rem .625rem;
    border-color: $gray-400;
    border-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
        color: white;
        background-color: #ECF9FF;
        border-color: #b4b9c5;
    }
    &:focus {
        box-shadow: none;
    }
}

.btn-outline-secondary:active:focus {
    box-shadow: none;
}

.btn-outline-light {
    font-size: 0.875rem;
    padding: .5rem 1.75rem;
}

.btn-light {
    font-size: 1.125rem;
    background-color: white;
    padding: .75rem 1.75rem;
    &:hover,
    &:focus {
        background-color: white;
    }
}

.btn-success {
    font-size: 1.125rem;
    padding: 0.75rem 1.75rem;
}

.btn-outline-success {
    font-size: 1.125rem;
    padding: 0.75rem 1.75rem;
    &:hover {
        border-color: $green;
        color: $green;
        background-color: transparent;
    }
}

.btn-outline-success:active:focus {
    box-shadow: none;
}

.btn-link {
    font-weight: 600;
    font-size: 0.875rem;
    color: #5FB73E;
    text-decoration: none;
    padding: 0;
    &:hover {
        box-shadow: none;
        color: $green;
    }
}