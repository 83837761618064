.form-range {
    --form-range-value: 50%;
    --form-range-thumb-color: #{$form-range-thumb-bg};
    --form-range-right-color: #{$blue-200};
    --form-range-left-color: #{$form-range-track-bg};
    &.warning {
        --form-range-right-color: #DEEAF4;
        --form-range-left-color: #{$orange};
        --form-range-thumb-color: #{$orange};
        &::-moz-range-thumb {
            background-color: $orange;
        }
    }

    &::-webkit-slider-runnable-track {
        background-color: var(--form-range-right-color);
        background: linear-gradient(to right, var(--form-range-left-color) 0%, var(--form-range-left-color) var(--form-range-value), var(--form-range-right-color) var(--form-range-value), var(--form-range-right-color) 100%)!important;
    }
    &::-moz-range-track {
        background-color: $orange;
        background: linear-gradient(to right, var(--form-range-left-color) 0%, var(--form-range-left-color) var(--form-range-value), var(--form-range-right-color) var(--form-range-value), var(--form-range-right-color) 100%)!important;
    }
    &::-webkit-slider-thumb {
        background-color: var(--form-range-thumb-color);
        border-radius:20px;
    }
    &::-moz-range-thumb {
        border-radius:20px;
        width: 25px;
        height: 25px;
    }
}