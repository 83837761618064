.bg-light {
  background-color: $blue-100 !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-white {
  background-color: #fafcff !important;
}

.bg-light-green {
  background-color: $light-green;
}

.bg-light-blue {
  background-color: #f4f8ff;
}

.bg-light-grey {
  background-color: #f4f6f6;
}

.border-top {
  border-top: 1px solid $blue-300 !important;
}

.border-top-grey {
  border: 1px solid #eaeaea;
}

.border-bottom {
  border-bottom: 1px solid $blue-300 !important;
}

.border-end {
  border-right: 1px solid $blue !important;
}

.border-start {
  border-left: 1px solid $blue !important;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-1 {
  border-radius: $border-radius-sm !important;
}

.rounded-2 {
  border-radius: $border-radius-md !important;
}

.text-dark {
  color: $dark !important;
}

.text-secondary {
  color: $gray-800 !important;
}

.text-muted {
  color: $gray-600 !important;
}

.text-aligned-justify {
  text-align: justify;
}

.text-dark-gray {
  color: $gray-900;
}

.text-gray {
  color: $gray-500;
}

.text-light-gray {
  color: $gray-300;
}

.text-gray-400 {
  color: $gray-400;
}

.text-yellow {
  color: #fed430;
}

.text-warning {
  color: $orange-100 !important;
}

.text-danger {
  color: $red !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.font-family-second {
  font-family: "Manrope";
}

.link-dark {
  color: $black !important;
  position: relative;
  &.active {
    color: $blue !important;
    font-weight: 600;
    span {
      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: $blue;
        transition: 0.5s;
        position: absolute;
        bottom: -7px;
        @media (max-width: 992px) {
          display: none;
        }
      }
    }
  }
  &:hover {
    color: $blue !important;
  }
}

.pt-35px {
  padding-top: 35px;
  @media (max-width: 992px) {
    padding-top: 18px;
  }
}

.pt-74px {
  padding-top: 74px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.pt-84px {
  padding-top: 84px;
  @media (max-width: 992px) {
    padding-top: 54px;
  }
}

.pt-104px {
  padding-top: 104px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.pt-120px {
  padding-top: 120px;
  @media (max-width: 992px) {
    padding-top: 55px;
  }
}

.loan-pt-120px {
  padding-top: 120px;
  @media (max-width: 992px) {
    padding-top: 55px !important;
  }
  @media (max-width: 1200px) {
    padding-top: 199px;
  }
}

.pt-138px {
  padding-top: 138px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.pt-140px {
  padding-top: 140px;
  @media (max-width: 992px) {
    padding-top: 120px;
  }
}

.pt-150px {
  padding-top: 150px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.pt-165px {
  padding-top: 165px;
  @media (max-width: 992px) {
    padding-top: 55px;
  }
}

.pt-177px {
  padding-top: 177px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.loan-pt-177px {
  padding-top: 177px;
  @media (max-width: 992px) {
    padding-top: 50px !important;
  }
  @media (max-width: 1200px) {
    padding-top: 259px;
  }
}

.pt-200px {
  padding-top: 200px;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
}

.loan-pt-200px {
  padding-top: 200px;
  @media (max-width: 992px) {
    padding-top: 50px !important;
  }
  @media (max-width: 1200px) {
    padding-top: 268px;
  }
}

.pt-210px-xl {
  @media (min-width: 992px) {
    padding-top: 210px;
  }
}

.pt-40px-md {
  @media (max-width: 991px) {
    padding-top: 40px;
  }
}

.about-us-paddings {
  padding-top: 165px;
  @media (max-width: 992px) {
    padding-top: 230px;
  }
  @media (max-width: 575px) {
    padding-top: 100px;
  }
}

.pb-75px {
  padding-bottom: 75px;
  @media (max-width: 992px) {
    padding-bottom: 50px;
  }
}

.pb-90px {
  padding-bottom: 90px;
  @media (max-width: 992px) {
    padding-bottom: 64px;
  }
}

.pb-105px {
  padding-bottom: 105px;
  @media (max-width: 992px) {
    padding-bottom: 64px;
  }
}

.pb-106px {
  padding-bottom: 106px;
}

.pb-120px {
  padding-bottom: 120px;
  @media (max-width: 992px) {
    padding-bottom: 55px;
  }
}

.pb-135px {
  padding-bottom: 135px;
  @media (max-width: 992px) {
    padding-bottom: 55px;
  }
}

.pb-175px {
  padding-bottom: 175px;
  @media (max-width: 1200px) {
    padding-bottom: 70px;
  }
}

.py-95px {
  padding: 95px 0;
  @media (max-width: 992px) {
    padding: 35px 0 24px 0;
  }
}

.mb-30px {
  margin-bottom: 30px;
}

.mb-40px {
  margin-bottom: 40px;
  @media (max-width: 992px) {
    margin-bottom: 28px;
  }
}

.mb-60px {
  margin-bottom: 60px;
  @media (max-width: 992px) {
    margin-bottom: 30px;
  }
}

.mb-12px {
  margin-bottom: 12px;
}

.mb-35px {
  margin-bottom: 35px;
}

.mb-140px {
  margin-bottom: 140px;
}

.mt-80px {
  margin-top: 80px;
}

.mh-314px {
  min-height: 314px;
  @media (max-width: 992px) {
    min-height: 100%;
  }
}

.mx-30px {
  margin: 0 2.5rem;
  @media (max-width: 992px) {
    margin: 0 2rem;
  }
}

.ms-6 {
  margin-left: 6%;
  @media (max-width: 1200px) {
    margin-left: 0;
  }
}

.underline-decor {
  &:after {
    display: block;
    content: "";
    background-image: url("../img/line-decor.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1.25rem;
    @media (max-width: 576px) {
      height: 0.75rem;
    }
  }
}

.lock-scroll {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}

.fs-1 {
  @media (min-width: 992px) and (max-width: 1400px) {
    font-size: 62px !important;
  }
  @media (max-width: 991px) {
    font-size: 70px !important;
  }
}

.lh-base {
  line-height: 1rem !important;
}

.lh-small {
  line-height: 0.875rem;
}

.lh-1 {
  line-height: 1.375rem !important;
}

.lh-2 {
  line-height: 1.625rem !important;
}

.lh-3 {
  line-height: 2.5rem !important;
  @media (max-width: 992px) {
    line-height: 1.875rem !important;
  }
}

.lh-sm {
  line-height: 2.125rem !important;
  @media (max-width: 992px) {
    line-height: 1.625rem !important;
  }
}

.lh-md {
  line-height: 2.875rem;
}

.lh-lg {
  line-height: 5rem !important;
}

.w-239px {
  width: 239px;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.modal-lg {
  max-width: 650px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

.test-class-for-depply {
  display: none;
}

.max-width-fit-content {
  max-width: fit-content;
}

.no-border {
  border: none !important;
}

.z-index-2 {
  z-index: 2;
}
