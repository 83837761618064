:root {
    --credit-menu-position: 96px;
}

.credit-menu {
    @media (min-width: 576px) and (max-width: 992px) {
        .container, .container-sm {
            max-width: initial;
        }
    }
    margin-top: 0px;
    .dropdown-toggle {
        font-size: 1.375rem;
        padding-top: .875rem;
        padding-bottom: .875rem;
        border-radius: 0;
        @media (min-width: 992px) {
            border-top-left-radius: $input-border-radius;
            border-top-right-radius: $input-border-radius;
        }
        &[aria-expanded="true"] {
            background-color: $blue-100;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    &.back-drop {
        &:before {
            position: fixed;
            top: var(--credit-menu-position);
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.6);
            content: '';
            z-index: 1;
        }
    }
    .when-menu-opened {
        display: none;
    }
    &.menu-opened {
        .when-menu-opened {
            display: inline;
        }
        .when-menu-closed {
            display: none;
        }
    }
    .credit-dropdown-menu {
        display: none;
        background: #FAFBFC;
        font-size: .938rem;
        position: fixed!important;
        top: var(--credit-menu-position);
        left: 0;
        right: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border: none;
        padding: 1.5rem 0 2.125rem 0;
        z-index: 1;
        @media (max-width: 992px) {
            padding-top: 0!important;
        }
        // @media (min-width: 993px) and (max-width: 1200px) {
        //     padding-top: 0
        // }
        &.show {
            display: block;
        }
    }
}

.dropdown-menu {
    margin-top: 0 !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    &.consumer-menu {
        background: #FAFBFC;
        font-size: .938rem;
        position: fixed!important;
        top: 94px;
        width: 100vw;
        border: none;
        padding: 1.5rem 0 2.125rem 0;
        @media (max-width: 992px) {
            top: 11px!important;
            padding-top: 0!important;
        }
        @media (min-width: 993px) and (max-width: 1200px) {
            padding-top: 30px!important;
        }
    }
}

.dropdown.credit-menu.menu-opened:before {
    position: fixed;
    top: var(--credit-menu-position);
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    content: '';
    z-index: 1;
    @media (max-width: 414px) {
        top: 143px!important;
    }
    @media (min-width: 415px) and (max-width: 576px) {
        top: 131px!important;
    }
    @media (min-width: 577px) and (max-width: 992px) {
        top: 153px!important;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
        top: 148px!important;
    }
}