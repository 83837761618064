.form-floating {
  position: relative;

  .prepend-placeholder {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    height: calc(3rem + 2px);
    padding: 1.375rem 0.875rem 0.5rem 0.875rem;
    font-weight: 600;
    font-size: 1.188rem;
    color: #727a8d;
    line-height: 1.25;
  }

  .form-control {
    font-weight: 600;
    font-size: 1.188rem;
    color: $black;
    padding: 1.313rem 0.875rem 0.5rem 0.875rem;
    &::placeholder {
      font-weight: 500;
      font-size: 0.688rem;
    }
    &:focus {
      box-shadow: none;
    }
  }
  label {
    font-weight: 400;
    font-size: 1.125rem;
    color: #637381;
    padding: 0.75rem;
    display: flex;
    align-items: center;
  }
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  font-weight: 500;
  font-size: 0.688rem;
  padding: 0.875rem 0.875rem 0 0.875rem;
  display: block;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.312rem;
  padding-bottom: 10px;
  ~ .prepend-placeholder {
    padding-top: 1.125rem;
    padding-bottom: 0.125rem;
  }
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3rem + 2px);
}

.form-floating > .form-control {
  &:focus {
    padding-top: 1.375rem !important;
  }
}

.form-floating.with-placeholder {
  > .form-control:focus,
  > .form-control:not(:placeholder-shown) {
    padding-left: 60px;
    padding-bottom: 0.8rem !important;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown) {
    ~ .prepend-placeholder {
      display: unset;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ .prepend-placeholder {
      display: block;
    }
  }
  // stylelint-enable no-duplicate-selectors
}
