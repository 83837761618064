.dropdown-item {
    font-weight: 600;
    font-size: 1.188rem;
    color: $black;
    padding: .75rem 1.438rem;
    &:hover,
    &:focus,
    &:active,
    &.active {
        color: $black;
        background: #E9F4FF!important;
    }
}

.calculator {
    .dropdown-menu {
        min-width: 100%;
        max-width: 100%;
        background: #FFFFFF;
        border: 1px solid #B4B9C5;
        border-radius: .25rem;
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
        padding: 0.625rem;
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: -1px!important;
        .dropdown-item {
            padding: 0.625rem 1rem;
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: $black;
            white-space: break-spaces;
            &:hover {
                background-color: $blue-100;
                border-radius: $border-radius-sm;
            }
        }
        &.show {
            border: none;
        }
    }
}