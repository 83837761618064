.input-group {
    &.active {
        .input-group-text {
            border: 1px solid $blue;
        }
    }
}

.input-group.input-style {
    .form-control {
        border-right: none;
        font-weight: 600;
        color: $black;
        &:focus,
        &:hover {
            box-shadow: none;
        }
        &.control-style::placeholder {
            font-weight: normal;
            color: $gray-800;
        }
    }
    .input-group-text {
        border-left: none;
    }
}

.input-group-composed {
    display: flex;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);

    select, input, button {
        border: $input-border-width solid transparent;
    }

    .bootstrap-select>.dropdown-toggle,
    .bootstrap-select>.dropdown-toggle.bs-placeholder,
    .bootstrap-select>.dropdown-toggle.bs-placeholder:active,
    .bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
    .bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-top-left-radius: $input-border-radius;
        border-top-right-radius: $input-border-radius;
    }

    .input-style {
        .input-group-text {
            border: $input-border-width solid transparent;
        }
    }

    .btn-right {
        border: none;
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
        border-left: $input-border-width solid $input-border-color !important;
    }

    .controls-divider {
        border-bottom: $input-border-width solid $input-border-color !important;
    }


    .form-floating {
        input {
            border: $input-border-width solid transparent;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: .2rem;
            border-bottom-right-radius: .2rem;
            padding-right: 48px;
            &:focus {
                border: $input-border-width solid $input-focus-color;
                padding-top: calc(1.25rem - 2px);
            }
        }

        .input-group-text {
            position: absolute;
            top: $input-border-width;
            right: $input-border-width;
            height: calc(100% - #{$input-border-width} * 2);
        }
    }

    .input-group:first-child {
        input {
            border-top-right-radius: .2rem;
            border-top-left-radius: .2rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}