$font-icons-family-name: "IconsFont";
@font-face {
	font-family: "IconsFont";
	src: url('../fonts/IconsFont.eot');
	src: url('../fonts/IconsFont.eot?#iefix') format('eot'),
		url('../fonts/IconsFont.woff2') format('woff2'),
		url('../fonts/IconsFont.woff') format('woff'),
		url('../fonts/IconsFont.ttf') format('truetype'),
		url('../fonts/IconsFont.svg#IconsFont') format('svg');
	font-display: swap;
}

@mixin icon-styles {
	font-family: "IconsFont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

$icon-name-arrow-down: "\E001"; 
$icon-name-arrow-next: "\E002"; 
$icon-name-arrow-step-mobile: "\E003"; 
$icon-name-arrow-step: "\E004"; 
$icon-name-back: "\E005"; 
$icon-name-cancel: "\E006"; 
$icon-name-cart: "\E007"; 
$icon-name-chat: "\E008"; 
$icon-name-check-fill: "\E009";
$icon-name-check-primary: "\E00A";
$icon-name-check: "\E00B";
$icon-name-checkbox: "\E00C";
$icon-name-clock: "\E00D";
$icon-name-close: "\E00E";
$icon-name-confirmation-play: "\E00F";
$icon-name-copy: "\E010";
$icon-name-dartboard: "\E011";
$icon-name-download: "\E012";
$icon-name-eye: "\E013";
$icon-name-flag: "\E014";
$icon-name-following: "\E015";
$icon-name-home-block: "\E016";
$icon-name-home: "\E017";
$icon-name-info: "\E018";
$icon-name-information-large: "\E019";
$icon-name-location: "\E01A";
$icon-name-menu: "\E01B";
$icon-name-minus: "\E01C";
$icon-name-phone-block: "\E01D";
$icon-name-phone: "\E01E";
$icon-name-play: "\E01F";
$icon-name-plus-form: "\E020";
$icon-name-plus: "\E021";
$icon-name-previous: "\E022";
$icon-name-profo: "\E023";
$icon-name-rejected: "\E024";
$icon-name-search: "\E025";
$icon-name-send-email: "\E026";
$icon-name-shield: "\E027";
$icon-name-star: "\E028";
$icon-name-sun: "\E029";
$icon-name-time: "\E02A";
$icon-name-upload: "\E02B";
$icon-name-warning: "\E02C";


@function icon-char($filename) {
	$char: "";

	@if $filename == arrow-down {
		$char: "\E001";
	}
	@if $filename == arrow-next {
		$char: "\E002";
	}
	@if $filename == arrow-step-mobile {
		$char: "\E003";
	}
	@if $filename == arrow-step {
		$char: "\E004";
	}
	@if $filename == back {
		$char: "\E005";
	}
	@if $filename == cancel {
		$char: "\E006";
	}
	@if $filename == cart {
		$char: "\E007";
	}
	@if $filename == chat {
		$char: "\E008";
	}
	@if $filename == check-fill {
		$char: "\E009";
	}
	@if $filename == check-primary {
		$char: "\E00A";
	}
	@if $filename == check {
		$char: "\E00B";
	}
	@if $filename == checkbox {
		$char: "\E00C";
	}
	@if $filename == clock {
		$char: "\E00D";
	}
	@if $filename == close {
		$char: "\E00E";
	}
	@if $filename == confirmation-play {
		$char: "\E00F";
	}
	@if $filename == copy {
		$char: "\E010";
	}
	@if $filename == dartboard {
		$char: "\E011";
	}
	@if $filename == download {
		$char: "\E012";
	}
	@if $filename == eye {
		$char: "\E013";
	}
	@if $filename == flag {
		$char: "\E014";
	}
	@if $filename == following {
		$char: "\E015";
	}
	@if $filename == home-block {
		$char: "\E016";
	}
	@if $filename == home {
		$char: "\E017";
	}
	@if $filename == info {
		$char: "\E018";
	}
	@if $filename == information-large {
		$char: "\E019";
	}
	@if $filename == location {
		$char: "\E01A";
	}
	@if $filename == menu {
		$char: "\E01B";
	}
	@if $filename == minus {
		$char: "\E01C";
	}
	@if $filename == phone-block {
		$char: "\E01D";
	}
	@if $filename == phone {
		$char: "\E01E";
	}
	@if $filename == play {
		$char: "\E01F";
	}
	@if $filename == plus-form {
		$char: "\E020";
	}
	@if $filename == plus {
		$char: "\E021";
	}
	@if $filename == previous {
		$char: "\E022";
	}
	@if $filename == profo {
		$char: "\E023";
	}
	@if $filename == rejected {
		$char: "\E024";
	}
	@if $filename == search {
		$char: "\E025";
	}
	@if $filename == send-email {
		$char: "\E026";
	}
	@if $filename == shield {
		$char: "\E027";
	}
	@if $filename == star {
		$char: "\E028";
	}
	@if $filename == sun {
		$char: "\E029";
	}
	@if $filename == time {
		$char: "\E02A";
	}
	@if $filename == upload {
		$char: "\E02B";
	}
	@if $filename == warning {
		$char: "\E02C";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-arrow-down {
	@include icon(arrow-down);
}
.icon-arrow-next {
	@include icon(arrow-next);
}
.icon-arrow-step-mobile {
	@include icon(arrow-step-mobile);
}
.icon-arrow-step {
	@include icon(arrow-step);
}
.icon-back {
	@include icon(back);
}
.icon-cancel {
	@include icon(cancel);
}
.icon-cart {
	@include icon(cart);
}
.icon-chat {
	@include icon(chat);
}
.icon-check-fill {
	@include icon(check-fill);
}
.icon-check-primary {
	@include icon(check-primary);
}
.icon-check {
	@include icon(check);
}
.icon-checkbox {
	@include icon(checkbox);
}
.icon-clock {
	@include icon(clock);
}
.icon-close {
	@include icon(close);
}
.icon-confirmation-play {
	@include icon(confirmation-play);
}
.icon-copy {
	@include icon(copy);
}
.icon-dartboard {
	@include icon(dartboard);
}
.icon-download {
	@include icon(download);
}
.icon-eye {
	@include icon(eye);
}
.icon-flag {
	@include icon(flag);
}
.icon-following {
	@include icon(following);
}
.icon-home-block {
	@include icon(home-block);
}
.icon-home {
	@include icon(home);
}
.icon-info {
	@include icon(info);
}
.icon-information-large {
	@include icon(information-large);
}
.icon-location {
	@include icon(location);
}
.icon-menu {
	@include icon(menu);
}
.icon-minus {
	@include icon(minus);
}
.icon-phone-block {
	@include icon(phone-block);
}
.icon-phone {
	@include icon(phone);
}
.icon-play {
	@include icon(play);
}
.icon-plus-form {
	@include icon(plus-form);
}
.icon-plus {
	@include icon(plus);
}
.icon-previous {
	@include icon(previous);
}
.icon-profo {
	@include icon(profo);
}
.icon-rejected {
	@include icon(rejected);
}
.icon-search {
	@include icon(search);
}
.icon-send-email {
	@include icon(send-email);
}
.icon-shield {
	@include icon(shield);
}
.icon-star {
	@include icon(star);
}
.icon-sun {
	@include icon(sun);
}
.icon-time {
	@include icon(time);
}
.icon-upload {
	@include icon(upload);
}
.icon-warning {
	@include icon(warning);
}
