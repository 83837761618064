.navbar-light {
  .navbar-nav {
    .nav-link {
      font-weight: 600;
      font-size: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
      @media (max-width: 992px) {
        font-size: 1.5rem;
      }
      @media (max-width: 576px) {
        padding-right: 38px;
        padding-left: 38px;
      }
      &.active {
        color: $blue;
      }
    }
  }
  .navbar-toggler {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .credit-limit-btn-container {
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (max-width: 575px) {
      padding: 0 38px;
    }
  }
}

.navbar-toggler {
  width: 35px;
  &:focus {
    box-shadow: none;
  }
  .toggler-expanded {
    display: block;
    position: fixed;
  }
  .toggler-collapsed {
    display: none;
  }
  &.collapsed {
    .toggler-expanded {
      display: none;
    }
    .toggler-collapsed {
      display: block;
    }
  }
}

.navbar {
  padding: 1.313rem 0;
  .navbar-toggler {
    z-index: 1000;
  }
  .navbar-collapse {
    .navbar-menu-brand-img {
      width: 150px;
    }
    .credits-menu-toggler {
      .when-menu-closed {
        display: inline;
      }
      .when-menu-opened {
        display: none;
      }
    }
    .dropdown-toggle {
      &.credits-menu-toggler {
        &::after {
          @include icon-styles;
          content: icon-char(arrow-down);
          border: none;
          font-size: 5px;
        }
      }
    }
    .credits-menu-dropdown {
      .dropdown-arrow-up {
        display: none;
      }
      .navbar-brand-img {
        padding-left: 38px;
      }
      @media (min-width: 1200px) {
        &.menu-opened {
          .dropdown-arrow-up {
            display: block;
            position: absolute;
            top: -9px;
            left: 34px;
            &:before {
              top: 0;
              border-color: transparent;
              border-width: 0 0.5rem 0.5rem 0.5rem;
              border-bottom-color: $dropdown-border-color;
              position: absolute;
              display: block;
              content: "";
              border-style: solid;
            }
            &:after {
              top: 1px;
              border-width: 0 0.5rem 0.5rem 0.5rem;
              border-color: transparent;
              border-bottom-color: #fff;
              position: absolute;
              display: block;
              content: "";
              border-style: solid;
            }
          }
        }
      }
    }
    .credits-menu {
      min-width: 558px;
      padding: 1rem 0;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      ul {
        padding-left: 0;
        li {
          list-style-type: none;
        }
      }
      .dropdown-icon {
        width: 28px;
        height: 28px;
      }
      .dropdown-item {
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
        color: $black;
        &:hover {
          background: $blue-100;
          border-radius: $border-radius-sm;
        }
      }
      @media (min-width: 1401px) {
        // &:before {
        //     position: absolute;
        //     top: -7px;
        //     left: 37px;
        //     display: inline-block;
        //     border-right: 7px solid transparent;
        //     border-bottom: 7px solid $dropdown-border-color;
        //     border-left: 7px solid transparent;
        //     content: '';
        // }
        // &:after {
        //     position: absolute;
        //     top: -6px;
        //     left: 38px;
        //     display: inline-block;
        //     border-right: 6px solid transparent;
        //     border-bottom: 6px solid #ffffff;
        //     border-left: 6px solid transparent;
        //     content: '';
        // }
      }
    }
    @media (max-width: 576px) {
      .navbar-nav {
        .nav-link {
          font-size: 1.125rem;
        }
      }
    }
    @media (max-width: 1199px) {
      &.show {
        opacity: 1.5;
      }
      background-color: white;
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.35s ease;
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: center !important;
      &.collapse {
        opacity: 0;
        display: flex;
        pointer-events: none;
      }
      &.collapsing[style*="height"] {
        opacity: 1;
        height: 100vh !important;
        z-index: 3;
      }
      &.collapsing {
        opacity: 0;
        height: 100vh;
        z-index: 3;
      }
      &.collapse.show {
        opacity: 1;
        pointer-events: auto;
        z-index: 3;
        overflow-y: auto;
        height: 100% !important;
      }
      .navbar-nav {
        margin-top: 1rem;
        margin-bottom: auto;
        text-align: left;
        width: 100%;
        @each $breakpoint, $container-max-width in $container-max-widths {
          @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            max-width: $container-max-width;
          }
        }
        .nav-link {
          font-weight: 600;
          font-size: 1.5rem;
        }
        .credits-menu-dropdown.menu-opened {
          .mobile-logo {
            display: none !important;
          }
          & ~ .nav-item {
            display: none;
          }
        }
        .credits-menu-toggler {
          margin-top: 30px;
          &::after {
            border: none !important;
            vertical-align: 0.1rem;
            @include icon-styles;
            content: icon-char(following);
            font-size: 0.75rem;
          }
          &.show {
            margin-top: -5px;
            padding-top: 0;
            .when-menu-closed {
              display: none;
            }
            .when-menu-opened {
              display: inline;
              font-size: 14px;
              color: $black;
            }
            .mobile-logo {
              display: none !important;
            }
            &::after {
              display: none;
            }
            &::before {
              display: inline-block;
              margin-right: 0.255em;
              border: none !important;
              vertical-align: 0.1rem;
              @include icon-styles;
              content: icon-char(previous);
              font-size: 0.625rem;
              color: $black;
              vertical-align: baseline;
            }
          }
        }
        .credits-menu {
          position: fixed;
          overflow-y: auto;
          overflow-x: hidden;
          top: 80px;
          right: 0px;
          font-size: 1.5rem;
          font-weight: 600;
          &.show {
            position: static;
            min-width: initial;
            display: flex;
            flex-direction: column;
            border: none;
            box-shadow: none;
            padding-top: 7px;
            overflow-y: auto;
            .dropdown-item {
              font-size: 1.125rem;
              white-space: break-spaces;
              padding: 0.813rem 1.625rem;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    padding: 0.813rem 0.5rem;
    .navbar-brand-img {
      height: 23px;
    }
  }
}

.nav-pills {
  .nav-link {
    font-weight: 500;
    font-size: 1rem;
    padding: 0 1rem;
    &:hover,
    &:focus {
      color: #d1ebf8;
    }
  }
  &.horizontal-scroll {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    position: relative;
    user-select: none;
    &::after {
      content: "";
      display: inline-block;
      min-width: 2.5rem;
    }
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: white;
  border-radius: 1.25rem;
  color: $blue;
  padding: 9px 12px;
}

.header-small {
  .navbar-light {
    background-color: white;
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: $blue;
  &::after {
    color: $blue;
  }
}

.credits-menu {
  .dropdown-item {
    font-weight: 600;
    font-size: 1.125rem;
    white-space: break-spaces;
    padding: 0.813rem 1.625rem;
    line-height: 22px;
  }
}
