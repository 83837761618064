@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
    z-index: 9999;
}

.popup-container {
    position: absolute;
    width: 625px;
    height: 455px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'HK Grotesk', sans-serif;
}

@media (max-width: 768px) {
    .popup-container {
        width: 100% !important;
        top: 100% !important;
        transform: translate(0%, -100%) !important;
        left: 0% !important;
        border-radius: 20px 20px 0px 0px;
    }

    .child-container {
        width: 100% !important;
        height: 342px !important;
        border-radius: 20px 20px 0px 0px;
    }

    .message-container {
        margin-bottom: 50px !important;
    }

    .timer-digit-frame-first,
    .timer-digit-frame-second,
    .timer-digit-frame-third,
    .timer-digit-frame-fourth,
    .timer-digit-frame-fifth,
    .timer-digit-frame-sixth {
        width: 35px !important;
        height: 52px !important;
    }

    .timer-digit {
        font-size: 44px !important;
    }

    .cirle {
        width: 8px !important;
        height: 8px !important;
    }

    .cirle:last-child {
        margin-top: 8px !important;
    }
}

.child-container {
    position: absolute;
    width: 625px;
    height: 340px;
    left: 0;
    top: 0;
    background: #F4F8FF;
    padding: 20px; /* Add padding to separate child elements */
    box-sizing: border-box; /* Include padding in the element's total width */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 20px;
}

.message-container {
    width: 100%;
    height: 68px;
    margin-bottom: 20px;
    position: static;
    margin-top: 20px;
}

.message {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    /* or 121% */
    text-align: center;
    letter-spacing: -0.257095px;
    color: #1D222E;
}

.timer-container {
    display: list-item;
    align-items: center;
    justify-content: center; /* Add justify-content: center to center the timer digit frames horizontally */
    list-style: none;
    position: sticky;
}

.timer-digit-frame-first,
.timer-digit-frame-second,
.timer-digit-frame-third,
.timer-digit-frame-fourth,
.timer-digit-frame-fifth,
.timer-digit-frame-sixth {
    position: relative; /* Change to relative position */
    display: inline-block; /* Display the timer digit frames inline */
    width: 49.52px;
    height: 72.76px;
    background: #1D222E;
    border-radius: 3.11901px;
    margin-right: 10px; /* Add margin-right to separate the timer digit frames */
    text-align: center; /* Center the timer digit frame content */
    vertical-align: middle; /* Align the timer digit frame content vertically */
}

.timer-digit-frame-sixth {
    margin-right: 0 !important;
}

.timer-sep-first,
.timer-sep-second {
    position: relative; /* Change to relative position */
    display: inline-block; /* Display the timer separators inline */
    width: 11.76px;
    height: 36.28px;

    margin-right: 10px; /* Add margin-right to separate the timer separators */
    text-align: center; /* Center the timer separator content */
    vertical-align: middle; /* Align the timer separator content vertically */
}

.timer-digit {
    position: absolute;
    width: 100%; /* Set width to 100% to expand within the parent */
    height: 100%; /* Set height to 100% to expand within the parent */
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 61px;
    line-height: 0;
    /* or 0% */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the timer digits horizontally and vertically */
    color: #FFFFFF;
}

.timer-labels {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Add align-items center to vertically center the labels */
    width: 100%;
    margin-top: 10px;
    position: absolute;
    left: 0;
    right: 0;
}

.timer-labels > div {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1D222E;
    text-align: center;
    flex-grow: 1;
}

.hours {
    transform: translateX(-10%); /* Adjust the horizontal position to center the "hours" label precisely */
}

.minutes {
    transform: translateX(5%); /* Adjust the horizontal position to center the "minutes" label precisely */
}

.seconds {
    transform: translateX(10%); /* Adjust the horizontal position to center the "seconds" label precisely */
}

.sign-button {
    position: absolute;
    width: 309px;
    height: 50px;
    left: 50%;
    top: 314px; /* Set the top position to 314px */
    transform: translateX(-50%); /* Center the sign-button horizontally */
    background: #6FBF51;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-modal {
    position: absolute;
    width: 45px;
    left: calc(50% - 45px / 2);
    top: 395px; /* Set the top position to 395px */
    bottom: 43px; /* Set the bottom position to 43px */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #5FB73E;
}

.circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #009ADE;

}

.circle:last-child {
    margin-top: 15px;
}

.popup-container,
.popup-wrapper {
    display: none;
}