.modal-dialog {
    @media (max-width: 576px) {
        margin: 0;
        display: block;
    }
}

.modal-content {
    border-radius: .5rem;
    border: 0;
    @media (max-width: 576px) {
        height: 100vh;
        border-radius: 0;
    }
}

.modal-header {
    padding: 27px 20px 30px 40px;
    border: none;
    @media (max-width: 576px) {
        border-bottom: 1px solid #EAEAEA;
        padding: 16px 20px 18px 20px;
    }
    h5 {
        @media (max-width: 576px) {
            font-size: 1rem!important;
        }
    }
}

.modal-body {
    padding: 0 30px 40px 30px;
    @media (max-width: 576px) {
        margin: auto 0;
        padding: 0;
        flex: 0;
    }
}