@media (max-width: 576px) {
    .mobile-container-padding {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }
    .mobile-container-padding-left {
        padding-left: 1.875rem;
    }
}

p {
    word-wrap: break-word;
    margin-bottom: 0;
}

.list-group-item {
    font-size: .875rem;
    color: $gray-800;
    padding: .5rem 0;
    border: 1px solid #D1D5DF;
}