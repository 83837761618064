@import "theme/theme";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-scroll {
  position: fixed;
  transition: 0.3s ease;
  width: 100%;
  height: 3rem;
  top: 0;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(3px);
  border-bottom: none;

  > div nav {
    height: 3rem;
    padding: 0;
  }

  @media (min-width: 1400px) {
    height: 4rem;
    > div nav {
      height: 4rem;
      border-bottom: 0;
    }
  }
}

.header-block-scroll {
  display: none;
  height: 4.125rem;
  @media (min-width: 1400px) {
    display: none;
    height: 5.5625rem;
  }
}

.hero {
  flex-grow: 1;
}

.footer {
  flex-shrink: 0;
}

.dropdown-menu[data-bs-popper] {
  right: 0;
}

.icon-info-style {
  color: $blue;

  &:hover,
  &:active {
    color: #107eb3;
  }
}

.plus-btn {
  color: $green;

  &:hover {
    color: #549b3c;
  }
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.list-group-items {
  li {
    font-size: 18px;

    &::before {
      display: inline;
      @include icon-styles;
      content: icon-char(check-primary);
      font-size: 0.75rem;
      color: $blue;
      padding-right: 0.5rem;
      vertical-align: middle;
      @media (max-width: 992px) {
        font-size: 12px;
      }
    }

    @media (max-width: 992px) {
      font-size: 12px;
    }
    list-style-type: none;
  }

  &.minus {
    padding-left: 0.75rem;

    li {
      font-size: 1.125rem;
      padding: 0 0 2px 1.6rem;
      text-indent: -2.188rem;

      &::before {
        display: inline;
        @include icon-styles;
        content: icon-char(minus);
        font-size: 1.5rem;
        color: $black;
        vertical-align: sub;
        padding-right: 0.625rem;
        @media (max-width: 992px) {
          font-size: 1rem;
        }
      }

      @media (max-width: 992px) {
        font-size: 0.938rem;
        padding: 0 0 0.25rem 1rem;
        text-indent: -1.625rem;
      }
      list-style-type: none;
    }
  }

  &.check {
    padding-left: 0.75rem;

    li {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.875rem;
      padding: 0 0 1.375rem 1.6rem;
      text-indent: -2rem;

      &::before {
        display: inline;
        @include icon-styles;
        content: icon-char(check);
        font-weight: 600;
        font-size: 1.375rem;
        color: $green;
        vertical-align: sub;
        padding-right: 0.625rem;
      }

      @media (max-width: 992px) {
        font-size: 0.938rem;
        padding: 0 0 0.625rem 1rem;
      }
      list-style-type: none;
    }
  }

  &.cancel {
    padding-left: 0.75rem;

    li {
      font-size: 1rem;
      line-height: 1rem;
      color: $black;
      padding: 0 0 1.125rem 1.6rem;
      text-indent: -2rem;

      &::before {
        display: inline;
        @include icon-styles;
        content: icon-char(cancel);
        font-size: 1.375rem;
        color: #9dadbb;
        vertical-align: sub;
        padding-right: 0.625rem;
      }

      @media (max-width: 992px) {
        font-size: 0.938rem;
        padding: 0 0 0.625rem 1rem;
      }
      list-style-type: none;
    }
  }
}

a {
  text-decoration: none;
}

textarea {
  resize: none;

  &::placeholder {
    font-size: 1.125rem !important;
  }
}

textarea.form-control {
  font-weight: 600;

  &::placeholder {
    font-weight: 400;
  }
}

.header-border {
  border-bottom: 1px solid $blue-300;
  @media (max-width: 1199px) {
    border-bottom: none;
  }
}

.landing-page {
  .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }

  &:not(.collateral) {
    .hero-img {
      @media (min-width: 2200px) {
        background-color: #F4F8FF;
        background-size: unset;
        background-position-y: 100px;
      }
    }
  }

  h2 {
    @media (max-width: 992px) {
      font-size: 1.875rem !important;
    }
  }

  .calculator {
    .price-text {
      font-size: 2rem;
      @media (max-width: 400px) {
        font-size: 1.7rem;
      }
    }
  }

  .hero {
    h1 {
      font-size: 43px;
    }

    .list-group-items {
      margin-bottom: 39px;
      @media (max-width: 991px) {
        margin-bottom: 24px;
      }
    }

    @media (max-width: 991px) {
      .container {
        padding-left: 0px;
        padding-right: 0px;

        .row {
          margin-left: 0px;
          margin-right: 0px;

          .hero-info {
            position: relative;
            padding: 0 1.875rem;

            .hero-info-wrapper {
              max-width: 214px;

              a {
                z-index: 2;
                position: sticky;
              }
            }

            h1 {
              font-size: 26px;
            }

            ul {
              font-size: 12px;
            }

            &::after {
              position: absolute;
              display: block;
              content: "";
              background-repeat: no-repeat;
              background-position: right bottom;
              background-size: contain;
              background-image: url("../img/hero-bg-mobile.png");
              top: 10px;
              width: 100%;
              right: 0px;
              height: 100%;
              @media (min-width: 576px) {
                background-image: url("../img/hero-bg-tablet.png");
              }
            }
          }

          .credit-calculator-col {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }

    .credit-calculator-col {
      .calculator {
        &.card {
          max-width: 410px;

          .card-header {
            background-color: transparent;
            border-bottom: 1px solid #e0e8f5;
            padding: 0;
          }

          .form-control {
            font-size: 0.875rem !important;
            font-weight: 600;

            &::placeholder {
              font-weight: 400;
            }
          }

          .form-label {
            font-size: 1.25rem !important;
          }

          .input-group {
            .input-group-text {
              font-weight: 400;
              font-size: 0.875rem;
              color: $black;
              padding-right: 0;
              border-right: none;
            }

            .form-control {
              padding-left: 0.25rem;
              border-left: none;
            }

            &:focus {
              border: 1px solid $blue;
            }
          }

          @media (max-width: 991px) {
            max-width: initial;
            width: 100%;
          }

          .fs-7 {
            @media (max-width: 991px) {
              font-size: 1.75rem !important;
            }
          }
        }

        .dropdown-toggle {
          font-weight: 600;
          font-size: 1.625rem;
          color: $black;
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 1.188rem 1.875rem 1.188rem 1.5rem;
          @media (max-width: 992px) {
            font-size: 12px;
            padding: 1rem 1.875rem 1rem 1.5rem;
          }

          &:after {
            position: absolute;
            top: 33px;
            right: 30px;
            width: 13px;
            height: 8px;
            @include icon-styles;
            content: icon-char(arrow-down);
            border: none;
            font-size: 0.563rem;
            @media (max-width: 992px) {
              top: 30px;
              right: 30px;
            }
          }

          &[aria-expanded="true"] {
            background-color: $blue-100;
            padding: 1.188rem 1.875rem 1.188rem 1.5rem;
            @media (max-width: 992px) {
              padding: 1rem 1.5rem;
            }

            &:after {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          min-width: 100%;
          max-width: 100%;
          background: #ffffff;
          border: 1px solid #b4b9c5;
          border-radius: 0.25rem;
          box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
          padding: 0.75rem;
          border-bottom-left-radius: 0.625rem;
          border-bottom-right-radius: 0.625rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-top: -1px !important;

          .dropdown-item {
            padding: 0.75rem 1rem;
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: $black;
            white-space: break-spaces;

            &:hover {
              background-color: $blue-100;
              border-radius: $border-radius-sm;
            }
          }

          &.show {
            border: none;
          }
        }

        .dropdown-icon {
          width: 40px;
          height: 40px;
        }

        sup {
          top: -20px;
          right: 15px;
        }
      }
    }
  }

  &.terms {
    h1 {
      font-size: 4rem;
      @media (max-width: 992px) {
        font-size: 2.188rem !important;
      }
    }

    .header-border {
      border-bottom: 0;
    }

    .hero-img {
      background-image: url("../img/primary-bg.svg");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      height: 345px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @media (max-width: 767px) {
        height: 190px;
      }
      @media (min-width: 768px) and (max-width: 992px) {
        height: 210px;
      }
    }

    @media (max-width: 767px) {
      .fs-13 {
        font-size: 1rem !important;
      }
    }
  }

  &.error {
    h2 {
      @media (max-width: 767px) {
        font-size: 2.188rem !important;
      }
    }

    .header-border {
      border-bottom: 0;
    }

    .hero {
      padding: 80px 0 140px 0;
      @media (max-width: 767px) {
        padding: 0 0 82px 0;
      }
    }

    .hero-img {
      background-image: url("../img/primary-bg.svg");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      height: 460px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @media (max-width: 991px) {
        background-image: url("../img/primary-bg-mobile.png");
      }
    }

    .fs-13 {
      font-size: 1rem !important;
    }

    .mb-140px {
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    .img-style {
      width: 205px;
      height: 87px;
      @media (max-width: 767px) {
        width: 155px;
        height: 65px;
      }
    }
  }

  &.index {
    .reviews {
      padding-bottom: 200px;
    }

    .hero-img {
      @media (min-width: 1941px) {
        background-image: url("../img/hero-bg-large.png");
        min-height: 910px;
      }
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) and (max-width: 1940px) {
        background-image: url("../img/hero-bg.png");
        min-height: 910px;
      }
    }

    .card.card-box {
      .card-body {
        padding: 35px 40px 30px 40px;

        .card-img {
          height: 54px;
          width: 54px;
        }
      }

      box-shadow: none;
      width: 100%;

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.consumer {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/consumer-bg.png");
        background-image: url("../img/consumer-bg.webp");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 254px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                background-image: url("../img/consumer-bg-mobile.png");
                background-image: url("../img/consumer-bg-mobile.webp");
                top: 30px;
                @media (min-width: 576px) {
                  background-image: url("../img/consumer-bg-tablet.png");
                  background-image: url("../img/consumer-bg-tablet.webp");
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      h4 {
        font-size: 1.125rem !important;
      }
      .fs-13 {
        font-size: 0.938rem !important;
      }
    }
  }

  &.loan {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/loan-bg.webp");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                background-image: url("../img/loan-bg-mobile.webp");
              }
            }
          }
        }
      }
    }
  }

  &.fast-loan {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/fast-loan-bg.webp");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                background-image: url("../img/fast-loan-bg-mobile.webp");
              }
            }
          }
        }
      }
    }
  }

  &.creditline {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/creditline-bg.webp");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                background-image: url("../img/creditline-bg-mobile.webp");
              }
            }
          }
        }
      }
    }
  }

  &.renovation {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/renovation-bg.png");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 0;
                background-image: url("../img/renovation-bg-mobile.png");
                @media (min-width: 576px) {
                  background-image: url("../img/renovation-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.merge {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }

      @media (min-width: 992px) {
        background-image: url("../img/merge-bg.png");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 0;
                background-image: url("../img/merge-bg-mobile.png");
                @media (min-width: 576px) {
                  background-image: url("../img/merge-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.refinancing {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/refinancing-bg.png");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 0;
                background-image: url("../img/refinancing-bg-mobile.png");
                @media (min-width: 576px) {
                  background-image: url("../img/refinancing-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.car {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }

      @media (min-width: 992px) {
        background-image: url("../img/car-bg.png");
        min-height: 870px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 80px;
                background-image: url("../img/car-bg-mobile.png");
                @media (min-width: 576px) {
                  background-image: url("../img/car-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.realestate {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/realestate-bg.png");
        min-height: 930px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 90px;
                background-image: url("../img/realestate-bg-mobile.png");
                background-size: cover;
                @media (min-width: 576px) {
                  background-image: url("../img/realestate-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.collateral {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }
      @media (min-width: 992px) {
        background-image: url("../img/collateral-bg.png");
        min-height: 950px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 50px;
                background-image: url("../img/collateral-bg-mobile.png");
                @media (min-width: 576px) {
                  background-image: url("../img/collateral-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.company {
    .hero-img {
      @media (max-width: 992px) {
        background-color: $home-hero-background-color;
        min-height: 500px;
        height: 1px;
      }

      @media (max-width: 1200px) {
        top: -53px;
        width: 100%;
      }
      @media (min-width: 992px) {
        background-image: url("../img/company-bg.png");
        min-height: 950px;
      }
    }

    .hero {
      .hero-info {
        min-height: 320px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                top: 70px;
                background-image: url("../img/company-bg-mobile.png");
                @media (min-width: 576px) {
                  top: 70px;
                  background-image: url("../img/company-bg-mobile.png");
                }
              }
            }
          }
        }
      }
    }
  }

  &.about-us {
    .hero-img {
      @media (min-width: 1941px) {
        background-image: url("../img/about-bg-large.png");
        min-height: 614px;
      }
      @media (min-width: 577px) and (max-width: 1940px) {
        background-image: url("../img/about-us-bg.png");
        min-height: 614px;
      }
      @media (max-width: 576px) {
        background-position: center;
        background-image: url("../img/about-us-bg-mobile.png");
        height: 425px;
      }
    }

    .hero {
      .hero-info {
        min-height: 254px;
      }

      @media (max-width: 991px) {
        .container {
          .row {
            .hero-info {
              &::after {
                background-image: none;
                @media (min-width: 576px) {
                  background-image: none;
                }
              }
            }
          }
        }
      }
    }

    .header-border {
      border-bottom: 0;
    }

    .work-details {
      padding-top: 90px;
      background-color: white;

      &:before {
        display: block;
        content: "";
        width: 100%;
        min-height: 70%;
        background: #e7f3ed;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 992px) {
          min-height: 100%;
        }
      }

      @media (max-width: 992px) {
        padding-top: 55px;
      }
    }

    .image-wrapper {
      min-height: 705px;
      @media (max-width: 992px) {
        min-height: 100%;
      }
    }

    .cart-image {
      position: absolute;
      right: 0;
      width: 48%;
      min-height: 705px;
      object-fit: cover;
      @media (min-width: 1200px) and (max-width: 1919px) {
        object-fit: contain;
      }
      @media (max-width: 1200px) {
        position: static;
        width: 100%;
        min-height: auto;
      }
      @media (min-width: 1921px) {
        max-width: 945px;
        right: initial;
      }
    }

    .card {
      border: 1px solid #c9ded3;
      border-radius: 0;
      box-shadow: none;
    }

    h2 {
      @media (max-width: 576px) {
        font-size: 1.875rem !important;
      }
    }

    .ls-1 {
      letter-spacing: -0.8;
    }

    .fs-1 {
      font-size: 5.313rem !important;
      @media (max-width: 576px) {
        font-size: 4.375rem !important;
      }
    }

    .fs-8 {
      @media (max-width: 576px) {
        font-size: 1.625rem !important;
      }
    }

    .fs-10 {
      @media (max-width: 576px) {
        font-size: 1.625rem !important;
      }
    }

    .fs-13 {
      @media (max-width: 576px) {
        font-size: 0.938rem !important;
      }
    }

    .fs-20 {
      @media (max-width: 576px) {
        font-size: 1.75rem !important;
        line-height: 2rem !important;
        letter-spacing: 0.3;
      }
    }

    .pt-35px {
      padding-top: 1.75rem !important;
    }

    .consumer-credit.pt-120px {
      @media (max-width: 992px) {
        padding-top: 120px;
      }
    }

    .icon-style {
      width: 135px;
      height: 135px;
      @media (max-width: 992px) {
        width: 105px;
        height: 105px;
      }
    }

    .contact-text {
      font-size: 1.375rem;
      @media (max-width: 992px) {
        font-size: 1.25rem;
      }
    }

    .line-height-48 {
      line-height: 48px;
    }

    .margin-top-42px {
      margin-top: 42px !important;
    }
  }

  &.faq {
    h2 {
      @media (max-width: 767px) {
        font-size: 1.75rem !important;
      }
    }

    .fs-10 {
      @media (max-width: 767px) {
        font-size: 1.125rem !important;
      }
    }

    .hero {
      padding: 60px 0 122px 0;
      @media (max-width: 767px) {
        padding: 30px 0 70px 0;
      }
    }

    .hero-img {
      height: 366px;
      @media (max-width: 991px) {
        height: 300px;
        background-image: url("../img/faq-primary-bg-mobile.png");
      }
    }

    .pt-35px {
      @media (max-width: 991px) {
        padding-top: 30px;
      }
    }

    :target {
      scroll-margin-top: 100px;
    }
  }

  &.contacts {
    .header-border {
      border-bottom: 0;
    }

    .hero-img {
      background-image: url("../img/primary-bg.svg");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      height: 440px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @media (max-width: 576px) {
        background-image: url("../img/primary-bg-mobile.png");
      }
      @media (min-width: 768px) and (max-width: 991px) {
        height: 320px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        height: 500px;
      }
    }

    .hero {
      padding: 80px 0 70px 0;
      @media (max-width: 991px) {
        padding: 30px 0 40px 0;
      }
    }

    .green-bottom-decor {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        left: 0;
        background-color: #e7f3ed;
      }
    }

    .iframe-wrapper {
      overflow: hidden;
      padding-bottom: 42%;
      position: relative;
      height: 0;
      @media (max-width: 576px) {
        padding-bottom: 127%;
      }
    }

    .iframe-wrapper iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    @media (max-width: 991px) {
      h2 {
        font-size: 1.875rem !important;
      }
      .fs-22 {
        font-size: 2.188rem !important;
      }
      .fs-16 {
        font-size: 0.75rem !important;
      }
      .fs-13 {
        font-size: 0.938rem !important;
      }
      .fs-11 {
        font-size: 1.125rem !important;
      }
      .fs-9 {
        font-size: 1.875rem !important;
      }
      .fs-5 {
        font-size: 2.25rem !important;
      }
      .pb-106px {
        padding-bottom: 0;
      }
      .contact-text {
        font-size: 0.75rem !important;

        span {
          font-size: 0.938rem !important;
        }
      }
      .question {
        .fs-16 {
          font-size: 0.938rem !important;
        }
      }
      .press {
        .contact-text {
          font-size: 1.25rem !important;
        }

        .fs-13 {
          font-size: 1rem !important;
        }
      }
    }

    .contact-text {
      font-weight: 600;
      font-size: 1rem;

      span {
        font-size: 1.125rem;
      }
    }

    .hero {
      position: relative;

      &::before {
        display: block;
        content: "";
        background-image: url("../img/decor-right-2.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        position: absolute;
        top: 70px;
        right: 0;
        height: 230%;
        width: 15%;
        z-index: -1;
        @media (max-width: 992px) {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        background-image: url("../img/decor-left-2.svg");
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        position: absolute;
        top: 90px;
        left: 0;
        height: 230%;
        width: 12%;
        z-index: -1;
        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    .btn-light {
      border: 1px solid #d5e6dd;
      border-radius: 25px;
      height: 46px;
      width: 164px;
      @media (max-width: 576px) {
        width: 145px;
      }
    }
  }

  &.blog {
    .header-border {
      border-bottom: 0;
    }

    .hero-img {
      background-image: url("../img/blog-hero-bg.png");
      background-color: #f4f8ff;
      height: 286px;
      z-index: -1;
      @media (max-width: 1200px) {
        background-image: none;
        background-color: #f4f8ff;
      }
    }

    .idea {
      padding: 20px 0 94px 0;

      &::before,
      &::after {
        display: none;
      }

      .card-footer {
        .btn-link {
          icon {
            font-size: 0.5rem !important;
          }
        }
      }

      @media (max-width: 991px) {
        padding: 40px 0 200px 0;
        .card {
          margin-right: 0;

          .card-body {
            padding: 0.938rem 0;

            .card-title {
              font-size: 0.938rem;
              line-height: 1.25rem;
            }
          }

          .card-footer {
            .btn-link {
              font-size: 0.75rem;

              icon {
                font-size: 0.5rem !important;
              }
            }

            span {
              font-size: 0.688rem !important;
            }
          }
        }
        .page-item {
          .page-link {
            font-size: 0.75rem;
          }
        }
      }
    }

    .main-blog {
      padding-bottom: 60px;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20%;
        left: 0;
        background-color: #f4f8ff;
        @media (max-width: 1200px) {
          bottom: initial;
          top: 0;
          height: 22%;
        }
      }

      .card {
        border: 1px solid #dfdfdf;
        border-radius: 0.625rem;
        box-shadow: none;

        .card-body {
          padding: 2.875rem;

          .card-title {
            font-size: 2.75rem;
            line-height: 3rem;
            color: $black;
          }

          .card-text {
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $gray-900;
          }
        }
      }

      @media (max-width: 992px) {
        padding-bottom: 48px;
        .card-title {
          font-size: 1.875rem !important;
        }
        .card-text {
          font-size: 1rem !important;
        }
      }

      @media (max-width: 1199.98px) {
        .card {
          border: none;
          background-color: transparent;
          .card-body {
            padding: 0;
          }
        }
      }
    }

    .card-block {
      position: absolute;
      bottom: -46px;
      left: 6%;
      z-index: 1;
    }
    @media (max-width: 1199.98px) {
      .card-block {
        position: unset;
      }
    }

    @media (max-width: 576px) {
      .fs-10 {
        font-size: 1.125rem !important;
      }
      h2 {
        font-size: 2.188rem !important;
      }
    }
  }

  .blog-info {
    h2 {
      margin-bottom: 22px !important;
      margin-top: 36px !important;
      font-size: calc(1rem + 1.4vw) !important;
      @media (min-width: 1200px) {
        font-size: 2.35rem !important;
      }
      @media (max-width: 576px) {
        font-size: 1.8rem !important;
      }
    }
    h3, h4, h5 {
      margin: 36px 0 18px !important;
    }
    h2, h3, h4, h5, a {
      color: #2980b9 !important;
    }
    p {
      line-height: 26px !important;
      margin-bottom: 10px !important;
    }
    ul li p {
      line-height: 24px !important;
      margin-bottom: 0 !important;
    }
    .blog-content > :first-child {
      margin-top: 36px !important;
    }
  }

  .similar-blogs {
    .card-img {
      object-fit: cover;
      @media (min-width: 1200px) {
        width: 360px;
        height: 260px;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        width: 295px;
        height: 197px;
      }
    }
  }

  &.blog-open {
    @extend .blog;

    h2 {
      font-size: 2.75rem !important;
    }

    .hero-img {
      height: 120px !important;
      @media (max-width: 1200px) {
        background-image: none;
        background-color: #f4f8ff;
        height: 300px !important;
      }
    }

    .idea {
      padding: 86px 0 94px 0 !important;
    }

    .blog-info {
      padding: 6px 0 158px 0 !important;
    }

    .main-blog {
      &::before {
        @media (max-width: 1200px) {
          bottom: initial;
          top: 0;
          height: 40%;
        }
      }
    }

    @media (max-width: 576px) {
      .fs-10 {
        font-size: 1.125rem !important;
      }
      h2 {
        font-size: 2.188rem !important;
      }
    }
    @media (max-width: 1200px) {
      .choose {
        display: none;
      }
      .pb-90px {
        padding-bottom: 27px;
      }
      .idea {
        padding: 50px 0 !important;

        h2 {
          font-size: 1.625rem !important;
        }

        .card {
          margin-right: 1.5rem;
        }
      }
      .blog-info {
        padding: 34px 0 94px 0 !important;

        .fs-10 {
          font-size: 1rem !important;
        }

        .fs-6 {
          font-size: 1.625rem !important;
        }
      }
    }
  }

  &.registration {
    background: #fafbfc;

    .container-xxl {
      padding: 0 2.125rem;
    }

    h2 {
      font-size: 2.375rem;
    }

    h5 {
      font-size: 1.125rem;
    }

    .subtitle {
      font-size: 1.125rem;
    }

    .form-control {
      color: $black;
      font-weight: 600;

      &.control-style::placeholder {
        font-weight: normal;
        color: $gray-800;
      }
    }

    .btn-close-block {
      top: 0;
      right: 0;
    }

    .hero {
      &::before {
        display: block;
        content: "";
        background-image: url("../img/main-decor-right.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        position: absolute;
        bottom: -250px;
        right: 0;
        height: 60%;
        width: 40%;
        z-index: -1;
        @media (max-width: 992px) {
          display: none;
        }
        @media (min-width: 993px) and (max-width: 1300px) {
          //bottom: -80px;
          height: 60%;
          width: 50%;
        }
      }

      &::after {
        display: block;
        content: "";
        background-image: url("../img/main-decor-left.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
        position: absolute;
        bottom: -150px;
        left: 0;
        height: 80%;
        width: 50%;
        z-index: -1;
        @media (max-width: 992px) {
          display: none;
        }
      }

      &.upload-decor {
        &::before {
          display: block;
          content: "";
          background-image: url("../img/main-decor-right.svg");
          background-repeat: no-repeat;
          background-position: bottom right;
          background-size: contain;
          position: absolute;
          bottom: -50px;
          right: 0;
          height: 100%;
          width: 30%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }

        &::after {
          display: block;
          content: "";
          background-image: url("../img/main-decor-left.svg");
          background-repeat: no-repeat;
          background-position: bottom left;
          background-size: contain;
          position: absolute;
          bottom: -190px;
          left: 0;
          height: 68%;
          width: 100%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &.upload-decor-large {
        &::before {
          display: block;
          content: "";
          background-image: url("../img/main-decor-right.svg");
          background-repeat: no-repeat;
          background-position: bottom right;
          background-size: contain;
          position: absolute;
          bottom: -50px;
          right: 0;
          height: 100%;
          width: 45%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }

        &::after {
          display: block;
          content: "";
          background-image: url("../img/main-decor-left.svg");
          background-repeat: no-repeat;
          background-position: bottom left;
          background-size: contain;
          position: absolute;
          bottom: -190px;
          left: 0;
          height: 127%;
          width: 100%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &.upload-decor-middle {
        &::before {
          display: block;
          content: "";
          background-image: url("../img/main-decor-right.svg");
          background-repeat: no-repeat;
          background-position: bottom right;
          background-size: contain;
          position: absolute;
          bottom: -140px;
          right: 0;
          height: 50%;
          width: 50%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }

        &::after {
          display: block;
          content: "";
          background-image: url("../img/main-decor-left.svg");
          background-repeat: no-repeat;
          background-position: bottom left;
          background-size: contain;
          position: absolute;
          bottom: -190px;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &.upload-decor-small {
        &::before {
          display: block;
          content: "";
          background-image: url("../img/main-decor-right.svg");
          background-repeat: no-repeat;
          background-position: bottom right;
          background-size: contain;
          position: absolute;
          bottom: -100px;
          right: 0;
          height: 25%;
          width: 35%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }

        &::after {
          display: block;
          content: "";
          background-image: url("../img/main-decor-left.svg");
          background-repeat: no-repeat;
          background-position: bottom left;
          background-size: contain;
          position: absolute;
          bottom: -180px;
          left: 0;
          height: 40%;
          width: 60%;
          z-index: -1;
          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      .card {
        border: 1px solid #eaeaea;
        border-radius: 0.5rem;

        &.offer {
          .card-body {
            padding: 3.875rem 3.563rem 5.438rem 3.563rem;
          }
        }
      }

      .price-block {
        background: $white;
        border: 2px solid #000000;
        color: $dark;
        font-weight: 600;
        font-size: 1.063rem;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }

      .offer-block {
        position: absolute;
        top: 0;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        .offer-circle {
          width: 70px !important;
          height: 70px;
          background: #ffffff;
          border: 4px solid #199cdb;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .modal-dialog {
      @media (max-width: 576px) {
        margin: 0;
        display: block;
      }
    }

    .modal-content {
      border: none;
      border-radius: 0.5rem;
      @media (max-width: 576px) {
        height: 100vh;
        border-radius: 0;
        border: none;
      }
    }

    .modal-body {
      padding: 45px 43px 60px 43px;

      .modal-title {
        font-size: 1.375rem;
      }

      @media (max-width: 576px) {
        margin: auto 0;
        padding: 53px 20px 90px 20px;
        flex: 0;
      }
    }

    .card.card-upload {
      border: 1px dashed #c8cedc;
      border-radius: 0.5rem;
      background-color: transparent;
      box-shadow: none;

      .card-body {
        background: #ffffff;
        border: 1px solid #d7dbe4;
        border-radius: 0.5rem;
        padding: 1.5rem;
        margin: 1.5rem;
        @media (max-width: 992px) {
          border-radius: 0.375rem;
          padding: 1.125rem 1rem 1.438rem 1rem;
          margin: 0.625rem;
        }
      }

      &.active {
        border: 1px dashed $blue;
        background: rgba(25, 156, 219, 0.09);

        .card-body {
          border: 1px solid $blue;
        }
      }

      &.danger {
        border: 1px dashed $red;

        .card-body {
          border: 1px solid $red;
        }
      }
    }

    .header-small {
      .header-secondary {
        background-color: white !important;
        padding: 0.813rem 0.5rem;
      }
    }

    .consumer-credit-bar {
      width: 39%;
      justify-content: space-between;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .progress {
      width: 117px;

      .progress-bar {
        border-radius: 0.5rem;

        &.success {
          background-color: $green;
        }
      }
    }

    .btn-check:active + .btn-light:focus,
    .btn-check:checked + .btn-light:focus,
    .btn-light.active:focus,
    .btn-light:active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: none;
    }

    .btn-success {
      padding: 0.6rem 1.75rem;
    }

    .btn-link {
      color: $dark;

      &:hover {
        color: $blue;
      }
    }

    @media (min-width: 1200px) {
      .col-lg-6.main {
        width: 42%;
      }
    }
    @media (max-width: 992px) {
      .container-xxl {
        padding: 0 1.25rem;

        &.mobile {
          padding: 0;
        }
      }
      h2 {
        font-size: 1.563rem !important;
      }
      h4 {
        font-size: 1rem !important;
      }
      h5 {
        font-size: 1rem !important;
      }
      .fs-4 {
        font-size: 3rem !important;
      }
      .fs-6 {
        font-size: 1.875rem !important;
      }
      .fs-7 {
        font-size: 1.75rem !important;
      }
      .fs-10 {
        font-size: 1rem !important;
      }
      .fs-11 {
        font-size: 1.25rem !important;
      }
      .fs-13 {
        font-size: 1rem !important;
      }
      .fs-15 {
        font-size: 0.875rem !important;
      }
      .fs-17 {
        font-size: 0.75rem !important;
      }
      .subtitle {
        font-size: 0.875rem !important;
      }
      .price-block {
        font-size: 0.938rem !important;
      }
      .form-floating label {
        font-size: 1rem !important;
      }
      .pt-35px {
        padding-top: 25px !important;
      }
      .pb-90px {
        padding-bottom: 0;
      }
      .py-6 {
        padding: 0.75rem 0;
      }
      .lh-2 {
        line-height: 1.375rem !important;
      }
      .form-control {
        font-size: 1rem !important;

        &::placeholder {
          font-size: 1rem !important;
        }
      }
      .bootstrap-select > .dropdown-toggle {
        font-size: 1rem !important;
      }
      .bootstrap-select > .dropdown-toggle.bs-placeholder {
        font-size: 1rem !important;
      }
      .bootstrap-select .dropdown-item {
        font-size: 1rem !important;
      }
      .form-label {
        font-size: 1rem !important;
      }
      .form-text {
        font-size: 0.75rem !important;
      }
      .checkbox label {
        font-size: 0.813rem !important;
      }
      .card.calculator {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        border: none;
      }
      .header-secondary {
        .fs-10 {
          font-size: 1rem !important;
        }
      }
      .footer {
        padding: 30px 6px;

        .fs-11 {
          font-size: 1.25rem !important;
        }
      }
    }

    .header-border {
      border-bottom: 1px solid #eaeaea;
    }
  }
}

.dropdown-arrow-up {
  display: block;
  position: absolute;
  top: -9px;
  left: 18px;

  &:before {
    top: 0;
    border-color: transparent;
    border-width: 0 9px 9px 9px;
    border-bottom-color: #ebddcc;
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
  }

  &:after {
    top: 1px;
    border-width: 0 9px 9px 9px;
    border-color: transparent;
    border-bottom-color: #fffbe5;
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
  }
}

.play-button-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play-btn {
  img {
    width: 68px;
    height: 68px;
    @media (max-width: 992px) {
      width: 39px;
      height: 39px;
    }
  }
}

.btn-close {
  opacity: 1;
}

.form-range {
  height: 0.5rem;
}

.help {
  position: relative;

  &::before {
    display: block;
    content: "";
    background-image: url("../img/decor-top-right.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    height: 45%;
    width: 50%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &::after {
    display: block;
    content: "";
    background-image: url("../img/decor-bottom-left.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
    position: absolute;
    bottom: -130px;
    left: 0;
    height: 55%;
    width: 50%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.consumer-credit {
  position: relative;

  &::before {
    display: block;
    content: "";
    background-image: url("../img/consumer-credit-right.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    top: -30px;
    right: 0;
    height: 107%;
    width: 100%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &::after {
    display: block;
    content: "";
    background-image: url("../img/consumer-credit-left.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    position: absolute;
    bottom: -212px;
    left: 0;
    height: 130%;
    width: 30%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
    @media (min-width: 993px) and (max-width: 1260px) {
      bottom: -360px;
      height: 120%;
    }
  }
}

.about {
  position: relative;

  &::before {
    display: block;
    content: "";
    background-image: url("../img/decor-top-right-2.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    top: -10px;
    right: 0;
    height: 160%;
    width: 100%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &::after {
    display: block;
    content: "";
    background-image: url("../img/decor-bottom-left-2.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
    position: absolute;
    top: 60px;
    left: -10px;
    height: 136%;
    width: 100%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.advantages {
  position: relative;

  .lh-0 {
    line-height: 1rem !important;
  }

  &::before {
    display: block;
    content: "";
    background-image: url("../img/decor-top-right-3.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    top: -100px;
    right: 0;
    height: 80%;
    width: 30%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .card {
    .card-header {
      padding: 2.5rem 2.5rem 2.5rem 2.5rem;
      @media (max-width: 992px) {
        padding: 1rem 1.875rem 1rem 1.875rem;
      }
    }

    .card-body {
      padding: 2.5rem 2.5rem 2.5rem 2.5rem;
      @media (max-width: 992px) {
        padding: 1.125rem 1.875rem 1.75rem 1.875rem;
      }
    }
  }

  .consumer-credit-col.fast-loan {
    img {
      width: 51px;
    }
  }

  @media (max-width: 992px) {
    .consumer-credit-col {
      position: relative;
      top: -0.5rem;
    }
    .credit-card-col {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  @media (min-width: 993px) {
    .credit-card-col {
      position: relative;
      right: -2.5rem;
    }
  }
}

.reviews {
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 52px 0;
    &.pb-200px {
      padding-bottom: 200px;
    }
  }
}

.cart {
  position: relative;

  &::before {
    display: block;
    content: "";
    background-image: url("../img/decor-top-right-4.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 20%;
    z-index: -1;
    @media (max-width: 1200px) {
      display: none;
    }
    @media (min-width: 1200px) and (max-width: 1600px) {
      height: 75%;
    }
  }

  &::after {
    display: block;
    content: "";
    background-image: url("../img/decor-top-left-4.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 80%;
    width: 25%;
    z-index: -1;
    @media (max-width: 1200px) {
      display: none;
    }
  }
}

.question {
  position: relative;

  &::before {
    display: block;
    content: "";
    background-image: url("../img/decor-right-3.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    position: absolute;
    top: -160px;
    right: 0;
    height: 150%;
    width: 30%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &::after {
    display: block;
    content: "";
    background-image: url("../img/decor-left-3.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    position: absolute;
    bottom: -193px;
    left: 0;
    height: 150%;
    width: 25%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.choose {
  padding: 86px 0 90px 0;
  @media (max-width: 992px) {
    padding: 135px 0 60px 0;
  }
}

.idea {
  position: relative;
  padding: 74px 0 94px;
  @media (max-width: 992px) {
    .card {
      margin-right: 1.5rem;
    }
  }

  .card {
    border: none;
    box-shadow: none;
    height: 100%;
    background-color: transparent;

    .card-body {
      padding: 1.25rem 0;
    }

    .card-footer {
      padding: 0;
    }
  }

  &::before {
    display: block;
    content: "";
    background-image: url("../img/decor-right.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0px;
    height: 138%;
    width: 30%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &::after {
    display: block;
    content: "";
    background-image: url("../img/decor-bottom-left-3.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    position: absolute;
    top: -948px;
    left: 0;
    height: 255%;
    width: 40%;
    z-index: -1;
    @media (max-width: 992px) {
      display: none;
    }
    @media (min-width: 1941px) {
      top: -1075px;
      height: 300%;
      width: 35%;
    }
  }

  @media (max-width: 992px) {
    padding: 50px 0 140px;
  }

  .mobile-carousel {
    display: flex;
    @media (max-width: 576px) {
      padding-left: 12px;
    }
  }
}

.questions {
  position: relative;
  padding: 46px 0;
  @media (max-width: 992px) {
    margin-top: 1.5rem;
    padding: 113px 0 73px;
  }

  .img-style-block {
    width: 100%;
    @media (max-width: 992px) {
      position: absolute;
      top: -130px;
      right: 15px;
      width: 220px;
      height: 220px;
    }
  }

  &.bg-orange {
    .accordion-body {
      color: white;
      padding: 12px 45px;
      p {
          margin: 8px 0;
      }
      a {
          text-decoration: underline;
          &:hover {
              color: $blue-100;
          }
      }
    }
  }
}

.why-us {
  .lh-0 {
    line-height: 1.625rem !important;
    @media (max-width: 992px) {
      line-height: 1rem !important;
    }
  }

  .lh-1 {
    line-height: 106px !important;
    @media (max-width: 992px) {
      line-height: 76px !important;
    }
  }

  .fs-13 {
    @media (max-width: 992px) {
      font-size: 15px !important;
    }
  }
}

.work-details {
  .card {
    .card-body {
      padding: 2.25rem;
      @media (max-width: 992px) {
        padding: 1.875rem;
      }
    }
  }
}

form {
  margin-bottom: 0px;
}

.invalid-feedback {
  color: $red !important;
  font-size: 0.75rem !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
  background-image: none;
}

.bootstrap-select.is-invalid .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle,
.was-validated .bootstrap-select select:invalid + .dropdown-toggle {
  border-color: $red !important;
}

.middle-arrow-decor::after {
  content: "";
  display: block;
  @include icon-styles;
  content: icon-char(arrow-step);
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50px;
  right: -40px;
  @media (max-width: 992px) {
    content: icon-char(arrow-step-mobile);
    font-size: 21px;
    top: 84px;
    right: auto;
    left: 30px;
    margin: 0;
  }
}

.img-block {
  position: absolute;
  top: -150px;
  right: 0;
  width: 423px;
  height: 458px;
  @media (max-width: 992px) {
    top: -300px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 260px;
    height: 279px;
  }
}

.img-style {
  width: 50px;
  height: 50px;
}

.icon-img {
  height: 70px;
  @media (max-width: 992px) {
    width: 45px;
    height: 45px;
  }
}

.icon-img-65 {
  height: 65px;
  @media (max-width: 992px) {
    width: 50px;
    height: 50px;
  }
}

.tooltip-inner {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  line-height: 14px;
  text-align: start;
}

.mobile-menu-footer {
  padding: 30px 23px 57px 14px;

  ul {
    li {
      font-size: 12px;
      padding: 0.25rem 0;
    }
  }
}

.page-item {
  margin: 0 4px;

  .page-link {
    font-weight: 600;
    font-size: 0.938rem;
    background-color: transparent;
    color: $black;
    border: 1px solid #d4d4d6;
    border-radius: 1.563rem;
    min-width: 40px;
    white-space: nowrap;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.next {
      padding: 0 1.063rem;
      width: initial;
    }

    span {
      font-size: 14px;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &:hover,
    &:focus {
      border-color: $blue;
    }

    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }

  &.active .page-link {
    color: $white;
    background-color: $blue;
  }
}

.pagination {
  @media (max-width: 576px) {
    width: 100%;
  }
}

.page-item {
  @media (max-width: 576px) {
    order: 1;

    &.prev, &.next {
      order: 2;
      margin-top: 12px;
    }
  }
}

.page-item:first-child .page-link {
  border-top-left-radius: 1.563rem;
  border-bottom-left-radius: 1.563rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 1.563rem;
  border-bottom-right-radius: 1.563rem;
}

.descr-border {
  padding-right: 21px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -15px;
    width: 1px;
    height: 33px;
    background: $blue;
  }

  &.border-gray {
    padding-right: 21px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 8px;
      top: 8px;
      width: 1px;
      height: 0.5rem;
      background: $gray-600;
    }
  }

  @media (max-width: 992px) {
    padding-right: 15px;
  }
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  border: 1px solid $gray-400;
  border-radius: 4px;
  color: $gray-800;
  font-weight: 400;
}

.bootstrap-select > .dropdown-toggle {
  padding: 13px 13px;
  border-radius: 4px;
  border-color: $gray-400;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: 0 !important;
  border-color: $gray-400;
}

.bootstrap-select {
  .dropdown-item {
    font-size: 1.125rem !important;
  }
}

.dropdown-toggle {
  &::after {
    border: none;
    display: inline;
    @include icon-styles;
    content: icon-char(arrow-down);
    font-size: 0.438rem;
    color: $gray-900;
  }
}

.border-bottom-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.border-top-radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-select.border-radius-top-style {
  .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .dropdown-toggle.bs-placeholder,
  .dropdown-toggle.bs-placeholder:active,
  .dropdown-toggle.bs-placeholder:focus,
  .dropdown-toggle.bs-placeholder:hover {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.bootstrap-select.border-bottom-radius {
  .dropdown-toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dropdown-toggle.bs-placeholder,
  .dropdown-toggle.bs-placeholder:active,
  .dropdown-toggle.bs-placeholder:focus,
  .dropdown-toggle.bs-placeholder:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.bootstrap-select.border-top-right-radius {
  .dropdown-toggle {
    border-top-right-radius: 0.25rem;
  }

  .dropdown-toggle.bs-placeholder,
  .dropdown-toggle.bs-placeholder:active,
  .dropdown-toggle.bs-placeholder:focus,
  .dropdown-toggle.bs-placeholder:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group.border-radius-bottom-style {
  .form-control {
    border-top-left-radius: 0;
    border-top: 1px solid transparent;

    &:focus,
    &.active {
      border-top: 1px solid $blue;
    }
  }

  .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 1px solid transparent;

    &:focus,
    &.active {
      border-top: 1px solid $blue !important;
    }
  }
}

.input-group.border-top-right-style {
  .form-control {
    border-top: 1px solid transparent;

    &:focus,
    &.active {
      border-top: 1px solid $blue;
    }
  }

  .input-group-text {
    border-top-right-radius: 0;
    border-top: 1px solid transparent;

    &:focus,
    &.active {
      border-top: 1px solid $blue !important;
    }
  }
}

.footer {
  padding: 50px 0;

  ul {
    li {
      padding: 4px 0;
      font-weight: 500;
      font-size: 14px;
    }
  }

  #read-more-accordion {
    p {
      font-size: 14px;
      padding: 6px 0;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #0e1016 !important;
  }

  .fs-14 {
    @media (max-width: 992px) {
      font-size: 0.813rem !important;
    }
  }

  .fs-13 {
    @media (max-width: 992px) {
      font-size: 0.938rem !important;
    }
  }

  .fs-11 {
    @media (max-width: 576px) {
      font-size: 1rem !important;
    }
  }

  .fs-19 {
    @media (max-width: 576px) {
      font-size: 12px !important;
    }
  }
}

.editable-text {
  &:hover {
    cursor: pointer;
    color: red;
  }
}

.editable-footer-links {
  &:hover {
    background: blue;
    cursor: pointer;
  }
}

.cms-modal {
  margin-top: 50px !important;
  height: 800px !important;
  top: unset !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.privacy-text {
  h3 {
    @extend .fw-bold;
    @extend .mb-4;
  }

  p {
    @extend .fs-13;
    @extend .mb-4;
  }
}

.personal-data-text {
  h3 {
    @extend .fw-bold;
    @extend .mb-3;
    @extend .mb-lg-4;
  }

  p {
    @extend .fs-13;
    @extend .mb-0;
  }

  table {
    width: 100%;
    margin-bottom: 8px;

    td {
      text-align: left;
      vertical-align: top;
      border-width: 1px;
      padding: 4px 8px;
    }
  }
}

.blog-back-btn {
  margin-top: 50px;
  margin-bottom: 155px;
}

.change-lang {
  cursor: pointer;
}

.txt-translate-btn {
  &.mode-on {
    border: 1px solid green !important;
  }

  &.mode-off {
    border: 1px solid red !important;
  }
}

.loader {
  background: url("../img/ajax-loader.gif") no-repeat center;
  width: 71px;
  height: 71px;
  margin: 0 auto;
}

.contact-form-success {
  .confirmed-text {
    margin-left: 8px;
    margin-top: 3px;
  }

  .icon-confirmed {
    background: url("../img/confirmed-icon.svg") no-repeat center;
    width: 34px;
    height: 35px;
  }

  @media (max-width: 576px) {
    .contacts-send-another {
      width: 100%;
    }
  }
}

.calc-header-title-realestate {
  font-size: 1.25rem !important;
}

.calc-header-title {
  font-size: 22px !important;
  @media (max-width: 430px) {
    font-size: 17px !important;
  }
}

.credit-type-phone-icon {
  font-size: 25px !important;
  padding-right: 0px !important;
}

.padding-y-14px {
  padding: 14px 0px !important;
  @media (max-width: 575px) {
    padding: 5px 0px !important;
  }
}

.padding-container-x-mobile {
  @media (max-width: 575px) {
    padding-left: 8px !important;
    padding-right: 14px !important;
  }
}

.blog-card-img {
  width: 360px;
  height: 260px;
  object-fit: cover;
  @media (max-width: 1200px) {
    width: 295px;
    height: 197px;
  }
  @media (max-width: 991px) {
    width: 336px;
    height: 223px;
  }
  @media (max-width: 768px) {
    width: 254px;
    height: 170px;
  }

  @media (max-width: 575px) {
    width: auto;
    height: 130px;
  }

  @media (max-width: 375px) {
    width: 160px;
    height: 115px;
  }
}

#blog-search {
  &:focus {
    border: 1px solid #b4b9c5;
    border-left: unset;
  }
}

.remove-margin-on-mobile {
  @media (max-width: 575px) {
    margin-bottom: 0px !important;
  }
}

.margin-34px-on-mobile {
  @media (max-width: 375px) {
    margin-bottom: 34px !important;
  }
}

.height-541px {
  @media (min-width: 991px) {
    height: 541px;
  }
}

.height-181px {
  @media (min-width: 1199px) {
    height: 171px;
    overflow-y: hidden;
  }
}

.not-found {
  height: 630px;
  @media (max-width: 575px) {
    background-color: #fff;
    height: 395px;
  }

  &__icon {
    color: #aad6eb;
    font-size: 90px;
    margin-top: -58px;
  }

  &__title {
    font-weight: 400;
    font-size: 34px !important;
    margin-bottom: 0px;
    @media (max-width: 575px) {
      font-size: 24px !important;
      margin-top: -10px;
    }
  }

  &__second--title {
    margin-top: 38px;
    color: #5fb73e;
    font-weight: 700;
    font-size: 14px;
    @media (max-width: 575px) {
      margin-top: 28px;
    }

    &:hover {
      color: #5fb73e;
    }
  }
}

.faq-text {
  font-size: 58px !important;
  @media (max-width: 1200px) {
    font-size: 40px !important;
  }
  @media (max-width: 992px) {
    font-size: 34px !important;
  }
}

.width-max-content {
  width: max-content !important;
}

